import {createI18n} from "vue-i18n";

const locales = ["ko", "ar", "cz", "de", "en", "fr", "es", "it", "ja", "kz", "pt", "ru", "sk", "sv"];
const messages = locales.reduce((accumulator, locale) => {
    accumulator[locale] = {
        ...require(`@/i18n/${locale}/menu.json`),
        ...require(`@/i18n/${locale}/label.json`),
        ...require(`@/i18n/${locale}/message.json`),
        ...require(`@/components/dtms-editor-v2.0/i18n/${locale}.json`),
    };
    return accumulator;
}, {});

export const i18n = new createI18n({
    locale: "en", // set locale
    fallbackLocale: "ko", // set fallback locale
    messages,
    legacy: false,
});
